<template>
  <card class="card-chart" v-if="groupTypeChart.status">

    <!-- TITLE -->
    <template slot="header">
      <h5 class="card-category">Chart by group type</h5>
    </template>
    <!-- END TITLE -->

    <!-- TABLE -->
    <div class="chart-area">
      <bar-chart
        :chart-data="groupTypeChart.chartData"
        :extra-options="groupTypeChart.extraOptions"
        :gradient-colors="groupTypeChart.gradientColors"
        :gradient-stops="groupTypeChart.gradientStops"
        :height="200"
      >
      </bar-chart>
    </div>
    <!-- END TABLE -->

  </card>
</template>

<script>
    import Vue from 'vue';
    import axios from 'axios';
    import BarChart from 'src/components/Charts/BarChart';
    import config from '@/config';
    import * as chartConfigs from '@/components/Charts/config';
    export default {
        name: "MonthlyChart.vue",
        components:{
            BarChart,
            [Option.name]: Option,
        },
        data(){
            return{
                groupTypeChart: {
                    chartData: {
                        labels: ["2018 Dec", "2019 Jan", "2019 Feb", "2019 Mar", "2019 Apr", "2019 May", "2019 Jun", "2019 Jul", "2019 Aug", "2019 Sep", "2019 Oct", "2019 Nov"],
                        datasets: []
                    },
                    extraOptions: {
                        maintainAspectRatio: false,
                        legend: {
                            display: true
                        },
                        responsive: true,
                        tooltips: {
                            backgroundColor: '#f5f5f5',
                            titleFontColor: '#333',
                            bodyFontColor: '#666',
                            bodySpacing: 4,
                            xPadding: 12,
                            mode: "nearest",
                            intersect: 0,
                            position: "nearest"
                        },
                        scales: {
                            yAxes: [{
                                barPercentage: 1.0,
                                gridLines: {
                                    drawBorder: false,
                                    color: 'rgba(29,140,248,0.0)',
                                    zeroLineColor: "transparent",
                                },
                                ticks: {
                                    suggestedMin: 50,
                                    suggestedMax: 50,
                                    padding: 0,
                                    fontColor: "#000000"
                                }
                            }],

                            xAxes: [{
                                barPercentage: 1.0,
                                gridLines: {
                                    drawBorder: false,
                                    color: 'rgba(220,53,69,0.1)',
                                    zeroLineColor: "transparent",
                                },
                                ticks: {
                                    padding: 0,
                                    fontColor: "#000000"
                                }
                            }]
                        }


                    },
                    gradientColors: config.colors.primaryGradient,
                    gradientStops: [1, 0],
                    status : false
                },
            }
        },
        mounted() {
            this.getgroupTypeChart();
        },
        methods:{
            /**
             * get groupType charts and refactor values
            **/
            getgroupTypeChart(){
                if (window.localStorage.getItem('Apochi_permissions').includes('mytrip_categoryChart')){
                    axios.post(Vue.prototype.websiteURL + 'admin/mytrip/panel/get/chart/get-group-type' , null , User.prototype.getHeader())
                        .then((response)=>{
                            let data   = [];
                            let colors = ['#00ffff','#6666ff','#ff00ff','#ff6699','#ffcc66','#99ff33','#0066ff'];
                            this.groupTypeChart.chartData.labels = response.data.data.labels;
                            for (let i = 0 ; i < Object.keys(response.data.data.values).length ; i++){
                                data.push({
                                    label: Object.keys(response.data.data.values)[i],
                                    fill: true,
                                    borderColor: colors[i],
                                    borderWidth: 2,
                                    borderDash: [],
                                    borderDashOffset: 0.0,
                                    data: Object.values(response.data.data.values)[i]
                                });
                            }
                            this.groupTypeChart.chartData.datasets = data;
                            this.groupTypeChart.status = true ;
                    })
                }
            },
        }
    }
</script>

<style scoped>

</style>
