<template>
  <card class="card-chart" v-if="MonthlyChart.status">

    <!-- TITLE -->
    <template slot="header">
      <h5 class="card-category">Monthly Chart</h5>
    </template>
    <!-- END TITLE -->

    <!-- TABLE -->
    <div class="chart-area">
      <bar-chart
        :chart-data="MonthlyChart.chartData"
        :extra-options="MonthlyChart.extraOptions"
        :gradient-colors="MonthlyChart.gradientColors"
        :gradient-stops="MonthlyChart.gradientStops"
        :height="200"
      >
      </bar-chart>
    </div>
    <!-- END TABLE -->

  </card>
</template>

<script>
    import Vue from 'vue';
    import axios from 'axios';
    import BarChart from 'src/components/Charts/BarChart';
    import config from '@/config';
    import * as chartConfigs from '@/components/Charts/config';
    export default {
        name: "MonthlyChart.vue",
        components:{
            BarChart,
            [Option.name]: Option,
        },
        data(){
            return{
                MonthlyChart: {
                    chartData: {
                        labels: ["2018 Dec", "2019 Jan", "2019 Feb", "2019 Mar", "2019 Apr", "2019 May", "2019 Jun", "2019 Jul", "2019 Aug", "2019 Sep", "2019 Oct", "2019 Nov"],
                        datasets: [
                            {
                                label: 'MonthlyChart',
                                fill: true,
                                borderColor: config.colors.primary,
                                borderWidth: 2,
                                borderDash: [],
                                borderDashOffset: 0.0,
                                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                            }
                        ]
                    },
                    extraOptions: {
                        maintainAspectRatio: false,
                        legend: {
                            display: false
                        },
                        responsive: true,
                        tooltips: {
                            backgroundColor: '#f5f5f5',
                            titleFontColor: '#333',
                            bodyFontColor: '#666',
                            bodySpacing: 4,
                            xPadding: 12,
                            mode: "nearest",
                            intersect: 0,
                            position: "nearest"
                        },
                        scales: {
                            yAxes: [{
                                barPercentage: 1.0,
                                gridLines: {
                                    drawBorder: false,
                                    color: 'rgba(29,140,248,0.0)',
                                    zeroLineColor: "transparent",
                                },
                                ticks: {
                                    suggestedMin: 50,
                                    suggestedMax: 50,
                                    padding: 0,
                                    fontColor: "#000000"
                                }
                            }],

                            xAxes: [{
                                barPercentage: 1.0,
                                gridLines: {
                                    drawBorder: false,
                                    color: 'rgba(220,53,69,0.1)',
                                    zeroLineColor: "transparent",
                                },
                                ticks: {
                                    padding: 0,
                                    fontColor: "#000000"
                                }
                            }]
                        }


                    },
                    gradientColors: config.colors.primaryGradient,
                    gradientStops: [1, 0],
                    status : false
                },
            }
        },
        mounted() {
            this.getMonthlyChart();
        },
        methods:{
            getMonthlyChart(){
                if (window.localStorage.getItem('Apochi_permissions').includes('mytrip_monthsChart')){
                    axios.post(Vue.prototype.websiteURL + 'admin/mytrip/panel/get/chart/get-filled-form-in-month' , null , User.prototype.getHeader())
                        .then((response)=>{
                            this.MonthlyChart.chartData.labels = response.data.data.labels;
                            this.MonthlyChart.chartData.datasets[0].data = response.data.data.fill;
                            this.MonthlyChart.status = true ;
                        })
                }
            },
        }
    }
</script>

<style scoped>

</style>
